
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton} from '@ionic/vue';
import { isPlatform } from '@ionic/vue';

import {defineComponent} from 'vue';
import SelectionList from "@/views/SelectionList.vue";
import ErrorList from "@/views/ErrorList.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';

import {Navigation} from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";


export default defineComponent({
  name: 'HomePage',
  components: {
    ErrorList,
    SelectionList,
    IonContent,
    IonPage,
    IonButton,
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      showplay: true,
      modules: [Navigation],
      step: 0,
      failures: 0,
      lock: [0, 0, 0],
      selection: "",
      options: [
        {
          correct: "river",
          audio: "1",
          audioText: "(Tina) Juchu, wir haben es geschafft, wir sind beim Fluss, los weiter",
          elements: {
            "river": "a1.1",
            "forest": "a1.3",
            "mountain": "a1.2",
          },
          "hints": [
            {
              text: "DREI rote Äpfel",
              audio: "1.1",
              audioText: "(Cora) drei rote Äpfel"
            },
            {
              text: "Da sind Faltspuren auf der Karte",
              audio: "1.2",
              audioText: "(Marei): Schau da sind Faltspuren auf der Karte"
            },
            {
              text: "Wir sollten die Karte falten, vielleicht führt der Weg dann woanders hin UND wir sammeln drei rote Äpfel",
              audio: "1.3",
              audioText: "(Tina) Vielleicht sollten wir die Karte falten, dann führt der Weg woanders hin UND wir sammeln drei rote Äpfel"
            },
            {
              num:1.4,
              text: "",
              audiotext: "(Mathias) Puh, langsam wird's kalt, wir würden gern weiter kommen?",
              audio: "1.4"
            },
            {
              text: "",
              audiotext: "(Cora) Cora müde...",
              audio: "1.5"
            },
            {
              text: "",
              audiotext: "(Marei) Wir haben doch jetzt echt schon genug Hinweise gegeben, oder?",
              audio: "1.6"
            },

            {

              text: "",
              audiotext: "(Marei) Knickt doch die Karte an den Markierungen, dann werden die beiden grün/roten Äpfel zu einem roten...",
              audio: "1.7"
            },

            {
              text: "",
              audiotext: "(Mathias) Ich glaube wir kommen beim Fluss raus",
              audio: "1.8"
            },

          ]
        },
        {
          correct: "hut",
          audio: "2",
          audioText: "(Cora) yipppiiehhh, Haus, Baum drinnen, mit Christkind!",
          elements: {
            "cave": "a2.2",
            "bridge": "a2.3",
            "shelter": "a2.4",
            "hut": "a2.1",
            "tree": "a2.5",
            "tree2": "a2.6",
            "tree3": "a2.7",
          },
          hints: [
            {
              text: "Die Wörter haben verschiedene Farben...",
              audio: "2.1",
              audioText: "(Mathias) mmmhhh... die Wörter haben verschiedene Farben"
            },
            {
              text: "Wir sollten Sätze mit den verschiedenen Farben machen",
              audio: "2.2",
              audioText: "(Tina): Ich glaube wir sollten jeweils Sätze aus den farbigen Wörtern machen"
            },
            {
              text: "Die Farben sollten die gleiche Reihenfolge wie im Regenbogen haben",
              audio: "2.3",
              audioText: "(Cora) Regenbogen gleiche Farben hat"
            },
            {
              text: "Die Farben im Regenbogen sind rot, orange, gelb, grün, blau und lila.",
              audiotext: "(Marei) Der Regenbogen ist rot, orange, gelb, grün, blau und lila",
              audio: "2.4"
            },
            {
              text: "Folgt den Richtungsanweisungen von der Brücke aus",
              audiotext: "(Marei) Folgt den Richtungsanweisungen von der Brücke aus",
              audio: "2.5"
            },
            {
              text: "Norden ist oben",
              audiotext: "(Mathias) Norden ist auf der Karte glaube ich auch oben",
              audio: "2.6"
            },
            {
              text: "",
              audiotext: "(Cora) kalt (weinen)",
              audio: "2.7"
            },
            {
              text: "",
              audiotext: "(Marei) Mir ist schon soooo kalt, Papa, wärme mich",
              audio: "2.8"
            },
            {
              text: "",
              audiotext: "(Marei) Mittlerweile hab's sogar ich hinbekommen... Ihr kommt beim Haus raus... ",
              audio: "2.9"
            }
          ]
        },
        {
          correct: "041",
          locks: [0, 1, 2],
          audio: "3",
          audioText: "(Marei) Mama, Papa, Cora, die Tür geht auf, ich freu mich so!",
          elements: {
            "star": "a3.2",
            "cross": "a3.7",
            "triangle": "a3.3",
            "rectangle2": "a3.6",
            "circle": "a3.4",
            "rectangle": "a3.5",
            "triangle2": "a3.1",

          },
          hints: [
            {
              text: "Auf dem Fussabstreifer sind ganz ähnliche Symbole",
              audio: "3.1",
              audioText: "(Marei) Schaut, auf der Fussmatte sind auch so Symbole..."
            },
            {
              text: "Licht scheint durch die Ritzen in der Tür",
              audio: "3.2",
              audioText: "(Cora): Licht scheint durch Tür"
            },
            {
              text: "Die Tür hat morsche Stellen",
              audio: "3.3",
              audioText: "(Tina) Die Tür ist schon etwas morsch... schaut, da scheint ein Dreieck fast rauszubrechen"
            },
            {
              text: "Man sollte das morsche Dreieck rausbrechen (schneiden)",
              audiotext: "(Mathias) Wir sollten das Stück einfach rausbrechen...",
              audio: "3.4"
            },
            {
              text: "Das Licht ergänzt die Formen auf der Fussmatte",
              audiotext: "(Marei) Da scheint jetzt Licht auf die Fussmatte! schaut, ein Stern",
              audio: "3.5"
            },
            {
              text: "Vielleicht gibt es noch eine morsche Stelle (auf der anderen Seite?)",
              audiotext: "(Cora) Vielleicht mehr Löcher?",
              audio: "3.6"
            },
            {
              text: "",
              audiotext: "(Marei) ich will endlich zum Weihnachtsbaum (schnief)",
              audio: "3.7"
            },
            {
              text: "",
              audiotext: "(Cora) Mama, Papa? Tür aufmachen? ",
              audio: "3.8"
            },
            {
              text: "",
              audiotext: "(Marei) Werden wir hier draussen erfrieren? an heilig abend? ",
              audio: "3.9"
            },
            {
              text: "",
              audiotext: "(Cora) Marei, wärmen, bitte ",
              audio: "3.10"
            },
            // {
            //   text: "",
            //   audiotext: "(Marei) waren das jetzt nicht wirklich schon genug Hinweise? ",
            //   audio: "3.11"
            // },
            {
              text: "",
              audiotext: "(Marei) Dreieck ausschneiden, Rechteck ausschneiden, seite mit Tür senkrecht Stellen, die 'Fußmatte' liegen lassen, von hinten mit ner Taschenlamp reinleuchten. Dann gibt's neue Symbole... ",
              audio: "3.12"
            },
            {
              text: "",
              audiotext: "(Marei) ok... jetzt hab ich's auch schon hinbekommen... Stern, Kreis und Kreuz ",
              audio: "3.13"
            }
          ]
        }
      ]
    }
  },
  methods: {
    slideChange(a: any, first: boolean) {

      this.lock[a.el.parentElement.dataset.pos] = a.realIndex;
      this.selection = this.lock.join("");

    },
    getImgUrl(item: string) {
      return "/assets/icon/" + item + ".png";
    },
    async next(reset: boolean) {
      // console.log(this.step, this.options);
      // console.log(this.step, this.options[this.step]);

      if (this.selection === this.options[this.step].correct) {
        console.log("success1");
        await this.playhint(['success', this.options[this.step].audio]);
        console.log("success2");
        this.step++;
        this.failures = 0;
        this.selection = "";
      } else {
        this.failures = Math.min(this.options[this.step].hints.length, this.failures + 1);
        if (reset)
          this.selection = "";
        this.playhint(['failure', this.options[this.step].hints[this.failures - 1].audio]);
      }
    },
    updateSelection(sel: string) {
      // console.log(sel);
      this.selection = sel;
    },
    fade(audio: any, to: number, duration = 200, steps = 10) {
      return new Promise((resolve) => {
        const curvol = audio.volume;
        const delta = to - curvol;
        const stepDelta = delta / steps;
        const stepDuration = duration / steps;
        //no audio volume on ios
        if(isPlatform('ios')){
          if(to==0){
            audio.pause()
          }else{
            audio.play()
          }
          resolve(0)
        }else{
          var i = setInterval(() => {

          const newVolume = Math.min(1, Math.max(0, audio.volume + stepDelta));
          console.log(stepDelta, newVolume);
          audio.volume = newVolume;
          if ((delta < 0 && audio.volume <= to) || (delta > 0 && audio.volume >= to)) {
            console.log("stopped fade");
            clearInterval(i);
            resolve(0);
          }
        }, stepDuration)
        }
      })

    },
    async playhint(audiofile: string[]) {

      if (audiofile.length > 0) {
        console.log("fading audio")
        var pos = 0;
        let bg = this.$refs.bgmusic as any;
        return this.fade(bg, 0)
            .then(() => new Promise((resolve) => {
              console.log('/assets/audio/' + audiofile[0] + ".mp3");

              let audio = new Audio('/assets/audio/' + audiofile[0] + ".mp3");
              audio.onended = () => {
                if (pos == audiofile.length - 1) {
                  console.log(pos);
                  resolve(1);
                } else {
                  audio.src = '/assets/audio/' + audiofile[++pos] + '.mp3';
                  console.log('/assets/audio/' + audiofile[pos] + ".mp3");
                  audio.play();
                }
              }
              audio.play();
            }))
            .then(() => {return this.fade(bg, 1)});

      }
      return Promise.resolve();
    }
  }
});
