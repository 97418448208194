<template>
  <ul class="errors" v-if="failures>0">
    <li><b>Hinweise:</b></li>
    <template v-for="(error, key) in hints " v-bind:key="key">
      <li v-if="key<failures && error.text!==''">{{error.text}}</li>
    </template>
  </ul>

</template>

<script>
export default {
  name: "ErrorList",
  props:{
    failures: Number,
    hints: Array
  }
}
</script>

<style scoped>
ul{
  margin:0;
  padding:0;
}
li{
  list-style: none;
  margin:0;
  padding:10px;

  border-bottom:1px solid gold;
}
li:last-child, li:first-child{
  border-bottom: none;
}
</style>