<template>
  <div class="options">
    <div class="option imageoption" v-for="(img,id) in elements" v-bind:key="id">
      <input type="radio" name="option" :id="id" :checked="selection===id" @change="selectItem(id)">
      <label :for="id"><img :src="getImgUrl(img)" alt=""/></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectionList",
  props:{
    elements: Array,
    selection: String
  },
  methods:{
    getImgUrl(item) {
      return "/assets/icon/"+item+".png";
    },
    selectItem(id){
      console.log("inner",id);
      this.$emit('selected',id)
    }
    //   var images = require.context('../assets/', false, /\.png$/)
    //   return images('./' + item + ".png")
    // }
  }

}
</script>

<style scoped>
.options{
  display: grid;
  gap: 5%;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 30px 10px;
}
.imageoption img{
  max-width: 100%;
}
.imageoption input {

  display: none;
}
.imageoption input+label{
  /*box-shadow: 0 0 20px white;*/
  box-shadow: 0 0 10px 3px transparent;
  border: 1px solid gold;
  border-radius: 10px;
  width:100%;
  height:100%;
  display: flex;
  padding: 10%;
  transition: all 200ms;
}
.imageoption input:checked+label{
  box-shadow: 0 0 10px 3px gold;

}
.imageoption input:checked+label img{
  filter: dropShadow(0 0 10px gold);
}
</style>